<template>
  <div>
    <a-button-group>
      <a-button-group size="small">
        <a-button type="primary" size="small" @click="goodsCreateModalVisible = true">替换</a-button>
        <a-button type="danger" size="small" @click="removeItem">移除</a-button>
      </a-button-group>
    </a-button-group>

    <GoodsCreateModal v-model="goodsCreateModalVisible" :item="item.goods_item" @create="onCreateGoods" />
  </div>
</template>

<script>
export default {
  components: {
    GoodsCreateModal: () => import("@/components/GoodsCreateModal"),
  },
  props: ["index", "item"],
  data() {
    return {
      goodsCreateModalVisible: false,
    };
  },
  methods: {
    removeItem() {
      this.$emit("remove", this.index);
    },
    onCreateGoods(goodsItem) {
      this.$emit("replace", this.index, { ...this.item, goods: goodsItem.id, goods_item: goodsItem });
    },
  },
};
</script>

<style scoped></style>
